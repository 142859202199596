<template>
    <div id="toc_container" class="no_bullets">
        <section class="table-content" v-show="!tableHide">
            <div class="table-content-title" @click="tableHide = !tableHide">
                Table of Contents
                <img src="../assets/minus.svg" alt="minus">
            </div>
            <ul class="toc_list">
                <li v-for="(item,index) in dataList" :key="index">
                    <a :href="'#TBC_' + index">{{ item.title }}</a>
                </li>
            </ul>
        </section>
        <section class="table-content hide" v-show="tableHide">
            <div class="table-content-title" @click="tableHide = !tableHide">
                Table of Contents
                <img src="../assets/plus.svg" alt="plus">
            </div>
        </section>

    </div>
</template>
<script>
export default {
    name: 'TocContainer',
    props: {
    dataList: {
      type: Array,
      required: true
    }
  },
    data() {
        return {
            tableHide: true
        }
    }
}
</script>