<template>
    <div class="version-container">
        <div class="header">
            <h1 class="main-title">{{ dataList.title }}</h1>
        </div>
        <div class="main-content">
            <div class="left-panel">
                <div class="logo-section">
                    <img :src="dataList.image" alt="YoWhatsApp" class="logo">
                    <div class="mod-name">{{ dataList.name }}</div>
                </div>
                <div class="description">
                    <p>{{ dataList.description }}</p>
                    <button class="downloadVersion" @click="downloadApk">Download</button>
                </div>
            </div>

            <div class="right-panel">
                <div class="version-info">APP Info</div>
                <table class="compare-table">
                    <tbody>
                        <tr>
                            <td>App</td>
                            <td>{{ dataList.AppDesc }}</td>
                        </tr>
                        <tr>
                            <td>Version</td>
                            <td>{{ dataList.version }}</td>
                        </tr>
                        <tr>
                            <td>Size</td>
                            <td>{{ dataList.size }}</td>
                        </tr>
                        <tr>
                            <td>License</td>
                            <td>{{ dataList.license }}</td>
                        </tr>
                        <tr>
                            <td>Operating System</td>
                            <td>{{ dataList.operatingSystem }}</td>
                        </tr>
                        <tr>
                            <td>Developer</td>
                            <td>{{ dataList.developer }}</td>
                        </tr>
                        <tr>
                            <td>Last Updated</td>
                            <td>{{ dataList.lastUpdated }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="button-group">
            <button class="action-btn "> Updated Version
            </button>
            <button class="action-btn "> Anti-Ban Version</button>
            <button class="action-btn "> Login Lssues Fixed </button>
            <button class="action-btn "> Official Version</button>
        </div>
    </div>
</template>
<script>
import { down } from 'cli-color/move';

export default {
    name: 'appInfo',
    props: {
        dataList: {
            type: Object,
            required: true
        },
        apk: {
            type: String
        }
    },
    data() {
        return {
            // apk: null,
        }
    },
    mounted(){
        // this.ondownload()
    },
    methods: {
        ondownload() {
            this.$server
                .getOfficalApk({
                    domain: 'gbwhatsapp.chat',
                    appName: 'GB_gbwhatsapp.chat',
                })
                .then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        this.apk = res.data.apk;
                    } else {
                        this.apk = process.env.VUE_APP_GBLINK;
                    }
                    this.$global.download(this.apk);
                });
        },
        downloadApk() {
            console.log(this.apk);
            if (this.apk) {
                this.$global.download(this.apk);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.version-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

    .header {
        text-align: center;
        margin-bottom: 30px;

        .main-title {
            font-size: 2.5rem;
            color: #2c3e50;
            margin-bottom: 10px;
        }

        .subtitle {
            font-size: 1.2rem;
            color: #7f8c8d;
        }
    }

    .main-content {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        gap: 30px;
        align-items: start;

        .left-panel {
            padding: 20px;
            background: #f8f9fa;
            border-radius: 10px;

            .logo-section {
                display: flex;
                align-items: center;
                flex-direction: column;

                .logo {
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                }

                .mod-name {
                    font-size: 1.5rem;
                    color: #2c3e50;
                    font-weight: 700;
                }
            }

            .description {
                line-height: 18px;
                font-size: 14px;
                color: #333;

                .downloadVersion {
                    width: 100%;
                    padding: 8px 0;
                    border-radius: 20px;
                    font-size: 16px;
                    border: 0;
                    color: #fff;
                    background: #000;
                }
            }
        }

        .right-panel {
            .version-info {
                color: #000;
                font-weight: 800;
            }

            .compare-table {
                width: 100%;
                border-collapse: collapse;
                background: #fff;
                border-radius: 8px;

                th,
                td {
                    font-size: 14px;
                    text-align: left;
                    border-bottom: 1px solid #e0e0e0;
                }

                td {
                    color: #555;
                }

                tr:hover {
                    background: #f8f9fa;
                }
            }
        }
    }

    .button-group {
        display: flex;
        gap: 15px;
        justify-content: space-around;
        margin-top: 10px;

        .action-btn {
            padding: 5px 30px;
            border-radius: 20px;
            border: 0;
            color: #20693f;
            cursor: pointer;
            transition: transform 0.3s ease;

            &:hover {
                transform: translateY(-2px);
            }
        }

        .primary {
            background: #3498db;
            color: white;
            border: none;
        }

        .secondary {
            background: #2ecc71;
            color: white;
            border: none;
        }

        .info {
            background: #9b59b6;
            color: white;
            border: none;
        }

        .warning {
            background: #e74c3c;
            color: white;
            border: none;
        }
    }

    @media (max-width: 768px) {
        padding: 15px;

        .header {
            .main-title {
                font-size: 1.8rem;
            }

            .subtitle {
                font-size: 1rem;
            }
        }

        .main-content {
            grid-template-columns: 1fr;
            gap: 20px;

            .left-panel {
                padding: 15px;

                .logo-section {
                    flex-direction: row;
                    gap: 10px;

                    .logo {
                        width: 60px;
                        height: 60px;
                    }

                    .mod-name {
                        font-size: 1.2rem;
                    }
                }

                .description {
                    line-height: 1.5;
                    font-size: 0.9rem;

                    .downloadVersion {
                        padding: 6px 0;
                        font-size: 0.9rem;
                    }
                }
            }

            .right-panel {
                .compare-table {

                    th,
                    td {
                        font-size: 0.9rem;
                        padding: 8px;
                    }
                }
            }
        }

        .button-group {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            padding: 0 10px;

            .action-btn {
                padding: 8px 15px;
                font-size: 0.9rem;
            }
        }
    }
}
</style>